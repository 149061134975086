import React from 'react';
import { object, string } from 'yup';

import {
  Layout,
  TitleAndMeta,
  Form,
  FormControl,
  TextInput,
  Single,
  TextArea,
  SubmitButton,
  FormHelper,
} from '../components';

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

export default () => {
  const schema = object().shape({
    name: string().label('Name').required(),
    email: string().label('Email').email().required(),
    message: string().label('Message'),
  });

  const handleSubmit = async (values: any, formEl: HTMLFormElement) => {
    await fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'get-in-touch',
        ...values,
      }),
    });
  };

  return (
    <Layout>
      <TitleAndMeta title="Get in Touch" />
      <Single>
        <h2>Get In Touch</h2>
        <Form
          name="get-in-touch"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
          schema={schema}
        >
          <input type="hidden" name="form-name" value="get-in-touch" />

          <FormHelper>
            Fields marked with an <span style={{ color: '#cf000f' }}>*</span>{' '}
            are required
          </FormHelper>
          <FormControl label="Name" name="name" required>
            <TextInput placeholder="Enter your name" />
          </FormControl>
          <FormControl label="Email" name="email" required>
            <TextInput type="email" placeholder="Enter your email" />
          </FormControl>
          <FormControl label="Message" name="message">
            <TextArea placeholder="Enter your message" />
          </FormControl>
          <SubmitButton>Submit</SubmitButton>
        </Form>
      </Single>
    </Layout>
  );
};
